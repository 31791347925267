<template>
	<v-row>
		<v-snackbar
			centered
			:color="snackbar.cor"
			v-model="snackbar.status"
			:timeout="snackbar.timeout"
			dark
		>
			<span class="font-weight-bold text-uppercase">{{snackbar.text}}</span>
			<template v-slot:action="{ attrs }">
				<v-btn class="font-weight-bold" text v-bind="attrs" @click="snackbar.status = false">Fechar</v-btn>
			</template>
		</v-snackbar>
		<VendaProdutoDialog
			v-if="dialogProduto"
			:random="Math.random()"
			:dialog="dialogProduto"
			:produto="produto"
			@onClose="dialogProduto = false"
			@onSearch="dialogProduto = false, busca.busca = $event, listar()"
		/>
		<v-dialog v-model="dialog" fullscreen scrollable transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar max-height="60" flat color="primary" dark>
					<v-btn text @click="dialog = !dialog">
						<v-icon class="mr-3">mdi-close</v-icon>
					</v-btn>
					<v-spacer />
					<v-toolbar-title>Filtros</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text @click="dialog = !dialog">Filtrar</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text>
					<v-row class="pa-3">
						<v-col cols="12">
							Promoção
							<v-checkbox
								:disabled="carregando"
								color="success"
								class="py-0 my-0 mb-n3"
								label="SOMENTE EM PROMOÇÃO"
								v-model="busca.promocao"
								@change="listar(0)"
							/>
						</v-col>
						<v-col cols="12">
							Estoque
							<v-checkbox
								:disabled="carregando"
								color="success"
								class="py-0 my-0 mb-n3"
								label="SOMENTE COM ESTOQUE"
								v-model="busca.estoque"
								@change="listar(0)"
							/>
						</v-col>
						<v-col cols="12">
							Categoria
							<v-checkbox
								:disabled="carregando"
								color="success"
								class="py-0 my-0 mb-n3"
								v-for="(item,i) in secoes.lista"
								:key="i"
								:label="item.secao"
								:value="item.idsecao"
								v-model="busca.categorias"
								@change="listar(0)"
							/>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="alerta" persistent max-width="575">
			<v-card>
				<v-card-title class="text-h5">
					<span class="mx-auto">Exclusão de produtos</span>
				</v-card-title>
				<v-divider />
				<div class="text-center pt-5">
					<v-icon class="text-h1" color="error">mdi-help-circle-outline</v-icon>
				</div>
				<v-card-text
					class="text-center text-subtitle-1"
				>Confirmar a exclusão de {{produtos.length}} produto(s) do pedido?</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn text color="primary" dark class="px-5" elevation="0" @click="alerta = false">Cancelar</v-btn>
					<v-btn color="primary" class="px-5" elevation="0" @click="limparPedido()">Confimar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12" lg="2" class="d-none d-lg-block">
			<v-card dark color="primary">
				<v-card-title>Filtros de busca</v-card-title>
				<v-card-text>
					<v-expansion-panels v-model="panel" flat multiple>
						<v-expansion-panel class="primary">
							<v-expansion-panel-header class="text-h6 px-0">Promoção</v-expansion-panel-header>
							<v-expansion-panel-content class="ml-n6">
								<v-checkbox
									:disabled="carregando"
									color="success"
									class="py-0 my-0 mb-n3"
									v-model="busca.promocao"
									@change="listar(0)"
								>
									<template v-slot:label>
										<div class="text-body-2">SOMENTE EM PROMOÇÃO</div>
									</template>
								</v-checkbox>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel class="primary">
							<v-expansion-panel-header class="text-h6 px-0">Estoque</v-expansion-panel-header>
							<v-expansion-panel-content class="ml-n6">
								<v-checkbox
									:disabled="carregando"
									color="success"
									class="py-0 my-0 mb-n3"
									v-model="busca.estoque"
									@change="listar(0)"
								>
									<template v-slot:label>
										<div class="text-body-2">SOMENTE COM ESTOQUE</div>
									</template>
								</v-checkbox>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel class="primary">
							<v-expansion-panel-header class="text-h6 px-0">Categoria</v-expansion-panel-header>
							<v-expansion-panel-content class="ml-n6">
								<v-checkbox
									:disabled="carregando"
									color="success"
									class="py-0 my-0 mb-n3"
									v-for="(item,i) in secoes.lista"
									:key="i"
									:value="item.idsecao"
									v-model="busca.categorias"
									@change="listar(0)"
								>
									<template v-slot:label>
										<div class="text-body-2">{{item.secao}}</div>
									</template>
								</v-checkbox>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" lg="10">
			<v-card>
				<v-card-title>
					<span class="d-none d-md-block">Tele-vendas</span>
					<v-spacer />
					<v-btn
						v-if="visualizacao == 'lista'"
						elevation="0"
						icon
						light
						class="primary--text d-none d-md-block"
						@click="visualizacao = 'card'"
					>
						<v-icon>mdi-card-bulleted</v-icon>
					</v-btn>
					<v-btn
						v-if="visualizacao == 'card'"
						elevation="0"
						icon
						light
						class="primary--text d-none d-md-block"
						@click="visualizacao = 'lista'"
					>
						<v-icon>mdi-format-list-text</v-icon>
					</v-btn>
					<v-btn
						:disabled="produtos.length == 0"
						elevation="0"
						class="mx-3"
						color="primary"
						@click="alerta = true"
					>
						<span class="d-none d-md-block">Limpar pedido</span>
						<v-icon class="ml-0 ml-md-3">mdi-close</v-icon>
					</v-btn>
					<v-menu
						v-model="menu"
						:open-on-click="true"
						:close-on-content-click="false"
						right
						offset-y
						tile
					>
						<template v-slot:activator="{ on }">
							<v-badge color="red" overlap :content="produtos.length" :value="produtos.length">
								<v-btn v-on="on" :disabled="produtos.length == 0" elevation="0" color="primary">
									<span class="d-none d-md-block">Ver pedido</span>
									<v-icon class="ml-0 ml-md-3">mdi-cart-outline</v-icon>
								</v-btn>
							</v-badge>
						</template>
						<v-card elevation="0" scrollable outlined>
							<v-card-title>
								Pedido
								<v-spacer />
								<v-btn
									:disabled="produtos.length == 0"
									fab
									elevation="0"
									color="primary"
									x-small
									to="/venda/pedido/faturar"
								>
									<v-icon>mdi-cart-outline</v-icon>
								</v-btn>
							</v-card-title>
							<v-divider />
							<v-card-text>
								<v-scroll-x-transition
									leave-absolute
									group
									tag="ul"
									v-if="produtos.length > 0"
									class="px-0"
								>
									<v-list-item v-for="(item, i) in produtos" :key="i">
										<v-list-item-avatar>
											<v-img v-if="item.linkimagem" :src="item.linkimagem" />
											<v-img v-else src="../../assets/icons/sem_imagem.png" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>{{item.idsubproduto}} - {{item.descricao}}</v-list-item-title>
											<v-list-item-subtitle>Quantidade: {{item.quantidade}}</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-content>
											<div class="text-right text-h6">
                                                <span class="d-none d-md-inline">{{(item.venda * item.valmultivendas) * item.quantidade | formataDinheiro}}</span>
												<v-btn
													class="ml-2"
													fab
													elevation="0"
													x-small
													color="red"
													dark
													@click="removerProdutoPedido(i)"
												>
													<v-icon>mdi-close</v-icon>
												</v-btn>
											</div>
										</v-list-item-content>
									</v-list-item>
								</v-scroll-x-transition>
								<v-list v-else>
									<v-list-item>
										<v-list-item-content>
											<div class="text-center mb-3">Nenhum produto adicionado ao carrinho</div>
										</v-list-item-content>
									</v-list-item>
								</v-list>
								<v-divider class="mt-5" />
								<v-list-item class="mt-3">
									<v-list-item-content>
										<div
											v-if="produtos.length > 0"
											class="text-right text-h5"
										>Total bruto: {{calculaTotal() | formataDinheiro}}</div>
										<div v-else class="text-right text-h5">Total bruto: R$ 0,00</div>
									</v-list-item-content>
								</v-list-item>
							</v-card-text>
							<v-divider />
							<v-card-actions>
								<v-btn
									v-if="produtos.length > 0"
									elevation="0"
									color="primary"
									block
									tile
									to="/venda/pedido/faturar"
								>
									Ver pedido
									<v-icon class="ml-3">mdi-cart-outline</v-icon>
								</v-btn>
								<v-btn v-else elevation="0" color="primary" block tile @click="menu = !menu">
									Fechar
									<v-icon class="ml-3">mdi-close</v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" class="d-flex mb-n7">
							<v-text-field
								class="d-none d-lg-block"
								label="Buscar produtos"
								clearable
								outlined
								dense
								v-model="busca.busca"
							/>
							<v-text-field
								class="d-inline-flex d-lg-none"
								label="Buscar produtos"
								clearable
								outlined
								dense
								v-model="busca.busca"
								append-outer-icon="mdi-menu"
								@click:append-outer="dialog = !dialog"
							/>
						</v-col>
						<template v-if="buscado">
							<v-col class="text--md-h6 text-body-1 font-weight-bold">Resultados para "{{buscado}}"</v-col>
							<v-col cols="auto" class="text-right text-body-1">{{dados.total}} produtos</v-col>
						</template>
						<template v-else>
							<v-col class="text--md-h6 text-body-1 font-weight-bold">Produtos em destaque</v-col>
							<v-col cols="auto" class="text-right text-body-1">{{dados.total}} produtos</v-col>
						</template>
					</v-row>
					<v-row v-if="carregandoSkeleton">
						<v-col v-for="i in 12" :key="i" cols="12" md="3">
							<v-skeleton-loader type="card" />
						</v-col>
					</v-row>
					<template>
						<v-row v-if="dados.total > 0">
							<v-col
								v-for="(item, i) in dados.lista"
								:key="i"
								cols="12"
								:md="visualizacao == 'card' ? 3: 12"
								class="mb-n3"
							>
								<v-card
									style="border-left: 5px solid #999999 !important;"
									color="grey lighten-4"
									elevation="0"
									v-if="visualizacao == 'card'"
								>
									<v-hover v-slot="{ hover }">
										<v-img
											v-if="item.linkimagem"
											width="100%"
											height="180"
											:src="item.linkimagem"
											:lazy-src="item.linkimagem"
											@click="get(item),dialogProduto = true"
										>
											<v-fade-transition>
												<v-sheet v-if="hover" color="primary" class="d-flex" style="opacity:0.30" height="180">
													<v-icon size="80" class="mx-auto" color="white">mdi-eye-outline</v-icon>
												</v-sheet>
											</v-fade-transition>
										</v-img>
										<v-img
											v-else
											width="100%"
											height="180"
											src="../../assets/icons/sem_imagem.png"
											lazy-src="../../assets/icons/sem_imagem.png"
											@click="get(item),dialogProduto = true"
										>
											<v-fade-transition>
												<v-sheet v-if="hover" color="primary" class="d-flex" style="opacity:0.30" height="180">
													<v-icon size="80" class="mx-auto" color="white">mdi-eye-outline</v-icon>
												</v-sheet>
											</v-fade-transition>
										</v-img>
									</v-hover>
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-card-title
												v-on="on"
												class="d-none d-md-block text-h6 text-truncate pt-0 px-1"
											>{{item.idsubproduto}} - {{item.descricao}}</v-card-title>
											<v-card-title
												v-on="on"
												class="d-block d-md-none text-h6 pt-0 px-1"
											>{{item.idsubproduto}} - {{item.descricao}}</v-card-title>
										</template>
										<span>{{item.idsubproduto}} - {{item.descricao}}</span>
									</v-tooltip>
									<v-card-text class="px-1 pb-0">
										<v-select
											persistent-hint
											dense
											:items="locaisretirada"
											item-value="idlocalretirada"
											item-text="localretirada"
											label="Local de retirada"
											v-model="item.idlocalretirada"
										/>
										<v-text-field
											v-if="item.embalagem == 'M2' || item.valmultivendas != 1"
											class="mb-n7"
											:suffix="(`${(item.quantidade*item.valmultivendas).toFixed(3)} ${item.embalagem}`).replaceAll('.',',')"
											persistent-hint
											dense
											label="Quantidade"
											type="number"
											v-model.number="item.quantidade"
											v-mask="'#########'"
										/>
										<v-text-field
											v-else-if="item.embalagem == 'MT'"
											class="mb-n7"
											:suffix="(`${(item.quantidade*item.valmultivendas).toFixed(3)} ${item.embalagem}`).replaceAll('.',',')"
											persistent-hint
											dense
											label="Quantidade"
											type="number"
											v-model.number="item.quantidade"
										/>
										<v-text-field
											v-else
											class="mb-n7"
											:suffix="(`${(item.quantidade*item.valmultivendas).toFixed(3)} ${item.embalagem}`).replaceAll('.',',')"
											persistent-hint
											dense
											label="Quantidade"
											type="number"
											v-model.number="item.quantidade"
											v-mask="'#########'"
										/>
										<v-row>
											<v-col
												cols="6"
												class="text-caption pr-0"
											>Estoque:{{item.estoque.toFixed(2)}} {{item.embalagem}}</v-col>
											<v-col
												cols="6"
												class="text-caption text-right pl-0"
											>Reserva:{{item.reserva.toFixed(2)}} {{item.embalagem}}</v-col>
											<template v-if="item.promocao > 0">
												<v-col class="pt-1 pb-0 mt-1">
													<v-badge icon="mdi-arrow-down" color="green" overlap>
														<span
															class="text-subtitle-2 text-truncate text-decoration-line-through"
														>{{item.venda | formataDinheiro}}</span>
													</v-badge>
												</v-col>
												<v-spacer />
												<v-col class="pt-1">
													<span
														class="text-h5 text-truncate font-weight-bold"
													>{{item.promocao | formataDinheiro}}</span>
												</v-col>
											</template>
											<template v-else>
												<v-col class="pt-1 pb-0 mb-n2"></v-col>
												<v-spacer />
												<v-col class="pt-1">
													<span class="text-h5 text-truncate font-weight-bold">{{item.venda | formataDinheiro}}</span>
												</v-col>
											</template>
										</v-row>
									</v-card-text>
									<v-card-actions>
										<v-row no-gutters v-if="item.dtfimpromocao != ''">
											<v-col cols="12" class="text-caption mt-n1 grey--text text--darken-2">Fim da Promoção:</v-col>
											<v-col
												cols="12"
												class="text-caption mt-n1 ml-4 grey--text text--darken-2"
											>{{item.dtfimpromocao}}</v-col>
										</v-row>
										<v-spacer />
										<v-btn fab elevation="0" x-small color="primary" @click="adicionarProdutoPedido(item)">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</v-card-actions>
								</v-card>
								<v-card
									v-if="visualizacao == 'lista'"
									elevation="0"
									style="border: 2px solid #999999;"
									min-width="800px"
								>
									<v-row
										align="center"
										class="my-2 pt-1 pb-2"
										no-gutters
										style="border-bottom: 1px solid #999999"
									>
										<v-col cols="auto" class="mx-2">
											<v-avatar>
												<v-img v-if="item.linkimagem" :src="item.linkimagem" :lazy-src="item.linkimagem" />
												<v-img
													v-else
													src="../../assets/icons/sem_imagem.png"
													lazy-src="../../assets/icons/sem_imagem.png"
												/>
											</v-avatar>
										</v-col>
										<v-col class="text-truncate">
											<v-tooltip top>
												<template v-slot:activator="{ on }">
													<span v-on="on">{{item.idsubproduto}} - {{item.descricao}}</span>
												</template>
												<span>{{item.idsubproduto}} - {{item.descricao}}</span>
											</v-tooltip>
										</v-col>
										<v-col cols="auto">
											<v-row no-gutters v-if="item.dtfimpromocao != ''">
												<v-col cols="12" class="mt-n1">Fim da Promoção:</v-col>
												<v-col cols="12" class="mt-n1 ml-4">{{item.dtfimpromocao}}</v-col>
											</v-row>
										</v-col>
										<v-col cols="auto" class="mx-2">
											<v-btn fab color="primary" x-small elevation="0" @click="get(item),dialogProduto = true">
												<v-icon>mdi-eye-outline</v-icon>
											</v-btn>
											<v-btn
												class="ml-1"
												fab
												elevation="0"
												x-small
												color="primary"
												@click="adicionarProdutoPedido(item)"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</v-col>
									</v-row>
									<v-row align="center" class="mb-n1 pt-1" no-gutters>
										<v-col class="ml-2 text-center">Estoque:{{item.estoque.toFixed(2)}} {{item.embalagem}}</v-col>
										<v-col class="mx-1 text-center">Reserva:{{item.reserva.toFixed(2)}} {{item.embalagem}}</v-col>
										<template v-if="item.promocao > 0">
											<v-col class="mx-1 text-center">
												<v-badge icon="mdi-arrow-down" color="green" overlap>
													<span class="text-decoration-line-through">{{item.venda | formataDinheiro}}</span>
												</v-badge>
												<strong class="ml-4">{{item.promocao | formataDinheiro}}</strong>
											</v-col>
										</template>
										<v-col v-else class="mx-1 text-center">
											<strong>{{item.venda | formataDinheiro}}</strong>
										</v-col>
										<v-col class="mb-n4 mx-1">
											<v-select
												outlined
												persistent-hint
												dense
												:items="locaisretirada"
												item-value="idlocalretirada"
												item-text="localretirada"
												label="Local de retirada"
												v-model="item.idlocalretirada"
												style="max-width: 200px"
											/>
										</v-col>
										<v-col class="mb-n4 mr-2">
											<v-text-field
												outlined
												v-if="item.embalagem == 'M2' || item.valmultivendas != 1"
												:suffix="(`${(item.quantidade*item.valmultivendas).toFixed(3)} ${item.embalagem}`).replaceAll('.',',')"
												persistent-hint
												dense
												label="Quantidade"
												type="number"
												v-model.number="item.quantidade"
												v-mask="'#########'"
											/>
											<v-text-field
												outlined
												v-else-if="item.embalagem == 'MT'"
												:suffix="(`${(item.quantidade*item.valmultivendas).toFixed(3)} ${item.embalagem}`).replaceAll('.',',')"
												persistent-hint
												dense
												label="Quantidade"
												type="number"
												v-model.number="item.quantidade"
											/>
											<v-text-field
												outlined
												v-else
												:suffix="(`${(item.quantidade*item.valmultivendas).toFixed(3)} ${item.embalagem}`).replaceAll('.',',')"
												persistent-hint
												dense
												label="Quantidade"
												type="number"
												v-model.number="item.quantidade"
												v-mask="'#########'"
											/>
										</v-col>
									</v-row>
								</v-card>
							</v-col>
							<v-col class="text-center" cols="12">
								<Paginacao
									:total="dados.total"
									@atualizar="listar"
									:pgLimitInt="pgLimit"
									:carregando="carregando"
									:pg="pgSelecionada"
								/>
							</v-col>
						</v-row>
						<v-row v-else>
							<v-col>
								<v-card flat class="text-center">
									<v-card-text class="text-h6">Nenhum produto encontrato.</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</template>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

import VendaProdutoDialog from "./VendaProdutoDialog";
import Paginacao from "../Widgets/Paginacao.vue";

export default {
	name: "VendaProduto",
	components: { VendaProdutoDialog, Paginacao },
	data: () => ({
		visualizacao: "card",
		snackbar: { status: false, timeout: 1500 },
		alerta: false,
		menu: false,
		dialog: false,
		dialogProduto: false,
		carregando: false,
		carregandoSkeleton: true,
		dados: { lista: [], total: 0 },
		secoes: {},
		filtroAlterado: false,
		busca: { busca: null, categorias: [], estoque: true, promocao: false },
		buscado: "",
		locaisretirada: [],
		panel: [0, 1, 2],
		produtos: [],
		produto: {},
		pgLimit: 12,
		aguardarBusca: "",
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "timeLimit", "usuario"]),
	},
	methods: {
		listarMaisVendidos() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}venda/produto/ranking`, {
					limit: this.pgLimit,
				})
				.then((res) => {
					let dados = res.data;
					this.dados.total = dados.length;
					dados.forEach((v) => {
						v.quantidade = 1;
						v.desconto = 0;
						v.idvendedor = this.usuario.idcliente;
					});
					this.dados.lista = dados;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dados = { lista: [], total: 0 };
					this.carregandoSkeleton = false;
				});
		},
		listar(pg) {
			this.pgSelecionada = pg;
			this.buscado = this.busca.busca;
			if ((this.buscado != "" && this.buscado) || this.busca.categorias.length > 0 || this.busca.promocao ) {
				let categorias = null;
				if (this.busca.categorias.length > 0) {
					categorias = this.busca.categorias.join(",");
				}
				clearTimeout(this.aguardarBusca);
                console.log('listar', this.buscado)
				this.aguardarBusca = setTimeout(() => {
					this.carregando = true;
					return axios
						.post(`${this.backendUrl}venda/produto/listar`, {
							limit: this.pgLimit,
							offset: this.pgLimit * pg,
							busca: this.busca.busca || null,
							estoque: this.busca.estoque || null,
							promocao: this.busca.promocao || null,
							categoria: categorias || null,
						})
						.then((res) => {
                            this.carregandoSkeleton = false;
							let dados = res.data;
							this.dados.total = dados.total;
							dados.lista.forEach((v) => {
								v.quantidade = 1;
								v.desconto = 0;
								v.idvendedor = this.usuario.idcliente;
							});
							this.dados.lista = dados.lista;
							this.carregando = false;
						})
						.catch(() => {
                            this.carregandoSkeleton = false;
							this.dados = { lista: [], total: 0 };
							this.carregando = false;
						});
				}, this.timeLimit);
			} else {
				this.listarMaisVendidos();
			}
		},
		get(item) {
			return axios
				.post(`${this.backendUrl}venda/produto/especificacoes`, {
					idsubproduto: item.idsubproduto,
				})
				.then((res) => {
					this.produto = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		listarLocalRetirada() {
			return axios
				.post(`${this.backendUrl}venda/produto/localretirada`)
				.then((res) => {
					this.locaisretirada = res.data;
					this.locaisretirada = this.locaisretirada.map((v) => {
						return {
							idlocalretirada: v.idlocalretirada,
							localretirada: `${v.idlocalretirada} - ${v.localretirada}`,
						};
					});
				});
		},
		listarSecoes() {
			this.secoes = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/secao/listar`, {
					idfabricante: null,
				})
				.then((res) => {
					this.secoes = res.data;
				});
		},
		limparPedido() {
			this.produtos = [];
			localStorage.removeItem("pedido");
			this.alerta = false;
			this.snackbar.text = "Todos os produtos removidos do pedido";
			this.snackbar.cor = "primary";
			this.snackbar.status = true;
		},
		adicionarProdutoPedido(produto) {
			if (produto.quantidade <= 0) {
				this.snackbar.text =
					"A quantidade do produto tem que ser superior a 0.";
				this.snackbar.cor = "error";
				this.snackbar.status = true;
				return;
			}
			if (!produto.idlocalretirada) {
				this.snackbar.text = "O local de retirada é obrigatório";
				this.snackbar.cor = "error";
				this.snackbar.status = true;
				return;
			}
            produto.flagpromocao = produto.flagpromocao == 'F' ? false : true;
			let prod = JSON.parse(JSON.stringify(produto));
			this.produtos.push(prod);
			localStorage.setItem("pedido", JSON.stringify(this.produtos));
			this.produtos.sort((a, b) => a.idsubproduto - b.idsubproduto);
			this.snackbar.text = "Produto adicionado ao carrinho (pedido)";
			this.snackbar.cor = "primary";
			this.snackbar.status = true;
		},
		removerProdutoPedido(i) {
			this.produtos.splice(i, 1);
			localStorage.setItem("pedido", JSON.stringify(this.produtos));
			this.snackbar.text = "Produto removido do pedido";
			this.snackbar.cor = "primary";
			this.snackbar.status = true;
		},
		calculaTotal() {
			// TODO:  Validar se os valores se deve usar preço à vista ou preço à prazo
			let valor = 0;
			this.produtos.forEach((v) => {
				valor += v.venda * v.quantidade;
			});
			return valor;
		},
		// TODO: Criar função para alternar entre preço à vista ou preço à prazo
		calculaFatorMultiplicacao(qtd, ftr) {
			console.log(parseFloat(qtd), parseFloat(ftr));
			return parseFloat(qtd) / parseFloat(ftr);
		},
		async init() {
			await this.listarLocalRetirada();
			await this.listarSecoes();
			const json = localStorage.getItem("pedido");
			const produtos = JSON.parse(json);
			if (produtos) {
				this.produtos = produtos;
			}
            if (this.$route.params.buscaProduto != undefined) {
                this.busca.busca = this.$route.params.buscaProduto
            } else {
                await this.listarMaisVendidos();
            }
		},
	},
	watch: {
		"busca.busca": function () {
			this.listar(0);
		},
	},
	created() {
		this.init();
	},
    // mounted() {
    //     console.log('monted')
    //     if (this.$route.params.buscaProduto) {
    //         console.log(this.$route.params.buscaProduto);
    //         this.busca.busca = this.$route.params.buscaProduto
    //         this.listar()
    //     }
    // },
};
</script>

<style scoped>
</style>